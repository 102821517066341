import request, { downloadFile } from "@/utils/axios";

// export function getBrand() {
//   return request.get("/campaign/brand");
// }

// 投放量级
export function getQuantity(params) {
  return request.get("/campaign/dashboard/quantity", params);
}

// 投放效果
export function getEffect(params) {
  return request.get("/campaign/dashboard/effect", params);
}

// 投放分布
export function getDistribution(params) {
  return request.get("/dashboard/distribution", params);
}

// 投放节奏
export function getTrend(params) {
  return request.get("/dashboard/trend", params);
}

// /dashboard/overview
export function getDashboardOverview(params) {
  return request.get("/dashboard/overview", params);
}

///dashboard/kol
export function getDashboardKol(params) {
  return request.get("/dashboard/kol", params);
}

// /dashboard/kol/report
export function downDashBoardKol(params, fileName) {
  return downloadFile("/dashboard/kol/report", params, fileName);
}

// dashboard/agency
export function getDashboardAgenct(params) {
  return request.get("dashboard/agency", params);
}

// dashboard/brand
export function getDashboardBrandInfo(params) {
  return request.get("/dashboard/brand", params);
}

// 根据品牌获取品线 /dashboard/brand
// dashboard/brandLine
export function getDashboardBrandLineByBrand(params) {
  return request.get("/dashboard/brandLine", params);
}

// 根据品牌+品线获取产品 /dashboard/brand
// dashboard/brandLine
export function getDashboardProduct(params) {
  return request.get("/dashboard/product", params);
}
